<template>
  <div>
    <span v-bind:class="{ 'mr-1': prefixLabel }">{{prefixLabel}}</span>
    <span class="mr-2">{{this.formatCurrency(amount, currency)}}</span>
  </div>
</template>

<script>
export default {
  computed: {

  },
  data () {
    return {
    }
  },
  props: {
    amount: Number,
    currency: String,
    prefixLabel: String
  }
}
</script>

<style lang="stylus" scoped></style>
